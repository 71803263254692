import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { colorPalette } from "&config/colors";

interface Props {
  placeHolder: string;
  value: string | number;
  hasError?: boolean;
  errorMessage?: string;
  handleTextChange: Function;
  handleSubmit?: Function;
  disabled?: boolean;
  required?: boolean;
  inputStyles?: any;
  type?: string;
  ref?: any;
}

const useStyles: Function = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colorPalette.alto,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: colorPalette.mine_shaftapprox,
    },
  },
});

const TextInput = (props: Props) => {
  const classes = useStyles();

  const {
    placeHolder,
    value,
    hasError,
    errorMessage,
    handleTextChange,
    handleSubmit = () => {},
    disabled = false,
    required,
    inputStyles,
    type,
    ref,
  } = props;

  //const formattedValue = value.replace(/" "/g, "");
  const formattedValue = "test";
  return (
    <FormControl
      sx={{ m: 1 }}
      fullWidth
      variant="outlined"
      className={classes.root}
      style={{
        margin: "0px",
      }}
    >
      <InputLabel
        htmlFor={formattedValue}
        required={typeof required !== "undefined" ? required : true}
        error={hasError}
      >
        {placeHolder}
      </InputLabel>
      <OutlinedInput
        id={formattedValue}
        value={value}
        error={hasError}
        disabled={disabled}
        type={type}
        label={placeHolder}
        required={required}
        onChange={(e) => handleTextChange(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleSubmit();
        }}
        aria-describedby={`component-error`}
        style={inputStyles}
        inputProps={inputStyles}
        ref={ref}
      />
      <FormHelperText
        style={{
          display: hasError ? "block" : "none",
          color: "red",
          fontSize: 10,
        }}
        id={`component-error`}
      >
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

const PasswordInput = (props: Props) => {
  const classes = useStyles();

  const {
    placeHolder,
    value,
    hasError,
    errorMessage,
    handleTextChange,
    disabled = false,
    handleSubmit = () => {},
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl
      sx={{ m: 1 }}
      fullWidth
      variant="outlined"
      className={classes.root}
      style={{ margin: "0px" }}
    >
      <InputLabel
        htmlFor="outlined-adornment-password"
        required
        error={hasError}
      >
        {placeHolder}
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        disabled={disabled}
        error={hasError}
        label={placeHolder}
        onChange={(e) => handleTextChange(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleSubmit();
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              disabled={disabled}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText
        style={{
          display: hasError ? "block" : "none",
          color: "red",
          fontSize: 10,
        }}
        id={`component-error-password`}
      >
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export { TextInput, PasswordInput };
