import React from "react";
import DashboardIcon from "&assets/icons/dashboard";
import TicketIcon from "&assets/icons/ticket";
import DiscountIcon from "&assets/icons/discount";
import PaperIcon from "&assets/icons/paper";
import LockIcon from "&assets/icons/lock";
import CarriersIcon from "&assets/icons/carriers";
import MerchantsIcon from "&assets/icons/merchants";
import ServicesIcon from "&assets/icons/services";

export const menu = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard",
    display: "Dashboard",
  },
  {
    icon: <DashboardIcon />,
    title: "Collection",
    to: "/collection",
    display: "Collection"
  },
  {
    icon: <DashboardIcon />,
    title: "Disbursement",
    to: "/disbursement",
    display: "Disbursement"
  },
  {
    icon: <CarriersIcon />,
    title: "Carriers Management",
    to: "/carriers",
    display: "CarrierManagement",
  },
  {
    icon: <MerchantsIcon />,
    title: "Merchants Management",
    to: "/merchants",
    display: "MerchentManagement",
  },
  {
    icon: <PaperIcon />,
    title: "Vouchers",
    items: [
      {
        title: "Vendor Vouchers",
        to: "/vouchers",
        display: "Vouchers",
      },
      {
       title: "Vouchers Request",
       to: "/vouchersRequest",
       display: "Vouchers",
      },
      //{
      //  title: "Merchants",
      //  to: "/vouchers/merchants",
      //  display: "Reports",
      //},
    ],
  },
  {
    icon: <TicketIcon />,
    title: "One-Time Purchase",
    to: "/one-time-purchase",
    display: "OneTimePurchase",
  },
  {
    icon: <TicketIcon />,
    title: "Blacklists",
    to: "/blacklists",
    display: "BlacklistManagement",
  },
  {
    icon: <ServicesIcon />,
    title: "Service Management",
    to: "/services",
    display: "Services",
  },
  {
    icon: <TicketIcon />,
    title: "Callcenter",
    to: "/callcenter",
    display: "Callcenter",
  },
  //{
  //  icon: <ServicesIcon />,
  //  title: "Product Management",
  //  items:[
  //    {title: "Product", to:'/products', display: "ProductManagement"}, 
  //    {title:"Category", to: "/categories", display: "ProductManagement"}
  //  ]
  //},
  //{
  //    icon: <DiscountIcon />,
  //    title: "Promotion & Discount",
  //    to: '/promotions'
  //  },
  {
    icon: <DiscountIcon />,
    title: "Settlement",
    to: "/settlements",
    display: "Settlements",
  },
  {
    icon: <ServicesIcon />,
    title: "Reports",
    items: [
      {
        title: "Transactions",
        to: "/reports?type=transaction-report",
        display: "Reports",
      },
      {
        title: "Settlement",
        to: "/reports?type=settlement-report",
        display: "Reports",
      },
      {
        title: "Billing",
        to: "/reports?type=billing-report",
        display: "Reports",
      },
    ],
  },
 
  {
    icon: <ServicesIcon />,
    title: "Payout Request",
    to: "/loan-requests",
    display: "LoanRequest",
  },
  {
    icon: <TicketIcon />,
    title: "Account Balance",
    to: "/loan-accounts-balance",
    display: "LoanRequest",
  },
];
