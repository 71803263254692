import React, { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridCellParams,
} from "@mui/x-data-grid";
import { truncate } from "lodash";

export default function TransactionReport({ onSearch, data = [], user, updateStatus }) {

  const [pageSize, setPageSize] = useState(25);
  const formatDate = (val) => {
    return moment(val).format("DD MMM YYYY HH:mm:ss A");
  };
  useEffect(() => {
    (async () => {
      const vals = {
        dateFrom: new Date(),
        dateTo: new Date(),
        country: "Pakistan",
        currency: "PKR",
      };
      await onSearch(vals);
    })();
  }, [onSearch]);

  const changeTransactionStatus = async(status, id) => {
    if(user.userTypeId !== "Walee") return;
    let updatedStatus = status === "Failed" ? "Success" : "Failed"
    const payload = {status: updatedStatus, id}
    await updateStatus(payload)
  }
  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "ID",
      width: 150,
      renderCell: ({ row }) => (
        <Box sx={{ color: "#6631F7" }}>{row.transactionId}</Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "msisdn",
      headerName: "Account",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "operator",
      headerName: "Operator",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "productName",
      headerName: "Product",
      width: 150,
      renderCell: ({ row }) =>
        truncate(row.productName, { length: 15, separator: "." }),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date/Time",
      width: 200,
      valueFormatter: ({ value }) => {
        return formatDate(value);
      },
      renderCell: ({ row }) =>
        moment(row.transactionDate).format("DD/MM/YYYY HH:mm:ss A"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "amount",
      headerName: "Transaction Amount",
      width: 150,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.amount?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },

    {
      field: "merchantShareValue",
      headerName: "Merchant Share (96%)",
      width: 200,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.merchantShareValue?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "waleeShareValue",
      headerName: "Multiverse Share",
      width: 150,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.waleeShareValue?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "netAmount",
      headerName: "Net Amount",
      width: 150,
      renderCell: ({ row }) => (
        <Box>
          {row.currency}. {row.netAmount?.toFixed(2)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "",
      width:  150,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: ({ row }) => (
        <>
        <Box
          sx={{
            padding: "0.2rem 1rem",
            borderRadius: "100px",
            backgroundColor: row.status === "Success" ? "#7BAD38" : "red",
            color: "#fff",
            cursor: user?.userTypeId === "Walee" ? "pointer" : "null"
          }}
          onClick = {()=> changeTransactionStatus(row.status, row._id)}
        >
          {row.status}
        </Box>
       
        </>
      ),
      headerAlign: "left",
      align: "left",
    },
  ];
  return (
    <>
      <PageHeader
        title="Transaction Report"
        onSearch={onSearch}
        type="transaction"
        defaultCurrency="PKR"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          "& .purple": {
            backgroundColor: "#F6F2FF",
            color: "#3C3C3C",
          },
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                getCellClassName={(params: GridCellParams<number>) => {
                  if (
                    params.field === "carrierShareValue" ||
                    params.field === "merchantShareValue" ||
                    params.field === "waleeShareValue"
                  ) {
                    return "purple";
                  } else {
                    return "";
                  }
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
