import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Box, Card, CardContent, Grid } from "@mui/material";
import * as Yup from "yup";
import { RootState } from "&store/store";

import { callcenterActions } from "./callcenter.slice";

import PageHeader from "&styled/page-header";
import AccordionComponent from "&styled/accordianComponent/accordianComponent.component";
import SelectComponent from "&styled/form/select";
import { Formik, FormikProps } from "formik";
import { TextInput } from "&styled/textField/textField.component";
import { SubmitButton } from "&styled/button/button.component";
import { servicesActions } from "&features/services/services.slice";
import JsonViewModal from "./jsonViewModal";


type ReduxProps = ConnectedProps<typeof connector>;

const OPERATORS = [
  {label: "Jazzcash", value: "jazzcash"},
  {label: "Easypaisa", value: "easypaisa"},
]

const CallcenterComponent = (props: ReduxProps) => {
  const {enquireTransaction, refundTransaction, getServices} = props;
  const [modalOpen, setModalOpen] = useState(false)
  const [services, setServices] = useState<any>([])
  const [jsonData, setJsonData] = useState<any>({})

  const handleCloseModal = () => {
    setJsonData({})
    setModalOpen(false)
  }

  useEffect(() => {
    (async()=>{
      const {payload} = await getServices()
      if(payload.length){
        const servicesArr: any = [];
        payload.forEach((s)=>{
          servicesArr.push({label: s.serviceName, value: s.serviceId})
        })
        setServices(servicesArr)
      }
    })()
    
  }, [getServices]);

  const initialValues = {
    operator:"",
    transactionNumber:"",
    serviceId: ""
  }
  const validation = Yup.object().shape({

  })

  const handleSubmit = async(values) => {
    const type = values.type;
    delete values.type;
    const func = type === "enquire" ? enquireTransaction : refundTransaction;
   const {payload} = await func(values)
   setJsonData(payload)
   setModalOpen(true)
  }

  return (
    <Box>
     <PageHeader title="Callcenter"/>

     <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof initialValues) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof initialValues>) => (
                <>
                  <AccordionComponent label="Enquire Transaction">
                  <Grid container spacing={1}>
                    <Grid item lg={3} xs={12}>
                    <SelectComponent
                                  value={formik.values.serviceId}
                                  onSelect={formik.handleChange("serviceId")}
                                  menuItems={services}
                                  placeHolder="Select Service"
                                />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                    <SelectComponent
                                  value={formik.values.operator}
                                  onSelect={formik.handleChange("operator")}
                                  menuItems={OPERATORS}
                                  placeHolder="Select Service Provider"
                                />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                    <TextInput
                      placeHolder={"Transaction ID"}
                      required={false}
                      value={formik.values.transactionNumber}
                      handleTextChange={formik.handleChange(
                        "transactionNumber"
                      )}
                      hasError={!!formik.errors.transactionNumber}
                      errorMessage={formik.errors.transactionNumber as string}
                    />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                    <SubmitButton
                      title="Enquire"
                      styles={{height: "100%"}}
                      handlePress={() => {
                        formik.setFieldValue("type", "enquire")
                        formik.handleSubmit();
                      }}
                    />
                    </Grid>
                  </Grid>
                 </AccordionComponent>
                  <AccordionComponent label="Refund Transaction">
                  <Grid container spacing={1}>
                    <Grid item lg={3} xs={12}>
                    <SelectComponent
                                  value={formik.values.serviceId}
                                  onSelect={formik.handleChange("serviceId")}
                                  menuItems={services}
                                  placeHolder="Select Service"
                                />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                    <SelectComponent
                                  value={formik.values.operator}
                                  onSelect={formik.handleChange("operator")}
                                  menuItems={OPERATORS}
                                  placeHolder="Select Service Provider"
                                />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                    <TextInput
                      placeHolder={"Transaction ID"}
                      required={false}
                      value={formik.values.transactionNumber}
                      handleTextChange={formik.handleChange(
                        "transactionNumber"
                      )}
                      hasError={!!formik.errors.transactionNumber}
                      errorMessage={formik.errors.transactionNumber as string}
                    />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                    <SubmitButton
                      title="Refund"
                      styles={{height: "100%"}}
                      handlePress={() => {
                        formik.setFieldValue("type", "refund")
                        formik.handleSubmit();
                      }}
                    />
                    </Grid>
                  </Grid>
                  </AccordionComponent>
                </>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    <JsonViewModal open={modalOpen} handleClose={handleCloseModal} values={jsonData}/>
    </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  // Map your redux state to your props here
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  enquireTransaction: callcenterActions.enquireTransaction,
  refundTransaction: callcenterActions.refundTransaction,
  getServices: servicesActions.getServices
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CallcenterComponentRedux = connector(CallcenterComponent);

export { CallcenterComponentRedux as CallcenterComponent };
