import React from 'react';

const VoucherDetails = ({ modalContent }) => {
  return (
    <div style={{ fontFamily: 'Arial', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <h2 style={{ color: '#6631F7', alignSelf: 'center' }}>{modalContent.payload.data.voucher_name}</h2>
      <div style={{ display: 'flex', gap: '2px' }}>
        <img src={modalContent.payload.data.icon_url} alt="icon" style={{ width: '100px', height: '100px' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', marginLeft: '50px' }}>
          <p><strong>Voucher Code:</strong> {modalContent.payload.data.voucher_code}</p>
          <p><strong>Status:</strong> {modalContent.payload.data.status === 1 ? 'Active' : 'Inactive'}</p>
        </div>
      </div>
      <div>
        <h3>Denominations:</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: "1rem" }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd' }}>
              <th style={{ padding: '10px', textAlign: 'left' }}>Code</th>
              <th style={{ padding: '10px', textAlign: 'left' }}>Name</th>
              <th style={{ padding: '10px', textAlign: 'left' }}>Currency</th>
              <th style={{ padding: '10px', textAlign: 'left' }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {modalContent.payload.data.denominations.map((denomination, index) => (
              <tr key={index}>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{denomination.denomination_code}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{denomination.denomination_name}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{denomination.denomination_currency}</td>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{denomination.denomination_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VoucherDetails;