import React from "react";
import ReactJson from 'react-json-view';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "34px",
  p: 2,
};

export default function JsonViewModal({ open, handleClose, values }) {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-modal"
        aria-describedby="confirmation-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              marginY: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{ color: "#2e2e2e", fontSize: "20px", fontWeight: "bold" }}
            >
              Transaction Summary
            </Box>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Divider />
            <ReactJson src={values}/>
        </Box>
      </Modal>
    </div>
  );
}
