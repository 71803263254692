import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { Vouchers } from "./vouchers.type";
import { WALEE_BASE_URL } from "&config/url";


const VOUCHER_URL = process.env.REACT_APP_VOUCHERS_URL;
/**
 * Initial state object
 */
const initialState: Vouchers = {
  data:[],
  request: [],
};


const getVouchers = createAsyncThunk(
  "vouchers/getVouchersCallStatus",
  async (arg: string, { rejectWithValue, getState, dispatch }: any) => {
    console.log("Parameters received:", arg); // Logging the received parameters

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    let pathname = ""
    switch (arg as unknown as string) {
      case "Unipin":
        pathname = "/via-unipin/get/list";
        break;
      default:
        return { data: [] };
        break;
    }
    try {
      /** make api call */
      const data = await trackPromise(
          axios.get(VOUCHER_URL!.concat(pathname), { headers }),
      );
      return data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getVoucherRequests = createAsyncThunk(
  "vouchers/getVoucherRequestsCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    let pathname = "/get/logs";
    
    try {
      /** make api call */
      const data = await trackPromise(
          axios.get(VOUCHER_URL!.concat(pathname), { headers }),
      );
      return data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getVouchersDetails = createAsyncThunk(
  "vouchers/getVouchersDetailsCallStatus",
  async (arg: object, { rejectWithValue, getState, dispatch }: any) => {

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    let pathname = ""
    const vendor = arg["vendor"];
    const voucherId = arg["voucherId"];
    switch (vendor as unknown as string) {
      case "Unipin":
        pathname = `/via-unipin/detail/${voucherId}`;
        break;
      default:
        return { }
        break;
    }
    
    try {
      /** make api call */
      const data = await trackPromise(
          axios.get(VOUCHER_URL!.concat(pathname), { headers }),
      );
      return data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getVouchersBalance = createAsyncThunk(
  "vouchers/getVouchersBalanceCallStatus",
  async (arg: string, { rejectWithValue, getState, dispatch }: any) => {

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    
    let pathname = ""
    switch (arg as unknown as string) {
      case "Unipin":
        pathname = "/via-unipin/balance";
        break;
      default:
        return { balance: 0}
        break;
    }
    
    try {
      /** make api call */
      const data = await trackPromise(
          axios.get(VOUCHER_URL!.concat(pathname), { headers }),
      );
      return data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const vouchersSlice = createSlice({
  name: "vouchers",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getVouchers.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(getVoucherRequests.fulfilled, (state, action) => {
      state.request = action.payload.data;
      // console.log("Voucher Requests:", action);
      
    });
  },
});


export const vouchersReducer = vouchersSlice.reducer;

export const vouchersActions = { 
  ...vouchersSlice.actions, 
  getVouchers,
  getVouchersDetails,
  getVouchersBalance,
  getVoucherRequests
};
