import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { CreditCardRedirect } from "./creditCardRedirect.type";
const STRIPE_URL = process.env.REACT_APP_STRIPE_URL;
/**
 * Initial state object
 */
const initialState: CreditCardRedirect = {};


const checkout = createAsyncThunk(
"service/checkout",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/checkout/add/redirect`;
   
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(STRIPE_URL!.concat(pathname),body)
      );
      console.log("Success response from service :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
/**
 * Feature slice Object
 * Automatically generates actions as per reducers
 */
const creditCardRedirectSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "creditCardRedirect",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setCreditCardRedirect: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(checkout.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(checkout.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(checkout.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const creditCardRedirectReducer = creditCardRedirectSlice.reducer;


export const creditCardRedirectActions = { ...creditCardRedirectSlice.actions, checkout };
