import React from "react";
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
// import { ReactComponent as CloseIcon } from "&assets/icons/close_button_dark.svg";

interface Props {
  title: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: Function;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "28px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: { title: string; onClose: Function }) => {
  const { title, onClose } = props;

  return (
    <DialogTitle>
      {title}
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

const Modal = (props: Props) => {
  const { title, children, open, setOpen, maxWidth = "sm" } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <BootstrapDialogTitle onClose={handleClose} title={title} />
      <DialogContent>{children}</DialogContent>
    </BootstrapDialog>
  );
};

export { Modal };
