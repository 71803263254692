import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "&store/store";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Card, CardContent } from "@mui/material";
import moment from "moment";

import { vouchersActions } from "../vouchers.slice";

import PageHeader from "&styled/page-header";


type ReduxProps = ConnectedProps<typeof connector>;


const VoucherRequest = (props: ReduxProps) => {
  const history = useHistory();

  const { 
    state,
    getVouchersRequest
   } = props;
  const { request } = state; // Accessing data from Redux state
  const [pageSize, setPageSize] = useState(25);
 
  const columns: GridColDef[] = [
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "requestType",
      headerName: "Request Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {

      await getVouchersRequest();
      setTimeout(() => {
        console.log(request);
        
      }, 1000);
    })();
  }, []);


  return (
    <>
      <PageHeader
        title="Vouchers Request"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>

            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={request} // Using data from Redux state
                rowHeight={100}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.vouchers,
});

const mapDispatchToProps = {
  // getCarriers: carriersActions.getCarriers,
  getVouchersRequest: vouchersActions.getVoucherRequests,
  // getVouchersDetails: vouchersActions.getVouchersDetails,
  // getVouchersBalance: vouchersActions.getVouchersBalance,
  // setData: vouchersActions.setData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const VouchersRequestRedux = connector(VoucherRequest);

export { VouchersRequestRedux as VouchersRequestComponent };