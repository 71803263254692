import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { servicesActions } from "&features/services/services.slice";
import SelectComponent from "&styled/form/select";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";

type ReduxProps = ConnectedProps<typeof connector>;
type OperatorsType = {
  country: string;
  name: string;
  mccmnc: string;
  createdDate: string;
};
type SelectFormtted = {
  label: string;
  value: string;
};
type CustomFields = {
  name: string;
  value: string;
};

const JC_TYPES = [
  {label:"Walee-Jazzcash", value:"walee-jazzcash"},
  {label:"Multiverse-Jazzcash", value:"multiverse-jazzcash"},
  {label:"Hashir-Jazzcash", value:"hashir-jazzcash"},
  {label:"Adonai-Jazzcash", value:"adonai-jazzcash"},
  {label:"Elohim-Jazzcash", value:"elohim-jazzcash"},
];

const EP_TYPES = [
  {label:"Walee-Easypaisa", value:"walee-easypaisa"},
  {label:"Multiverse-Easypaisa", value:"multiverse-easypaisa"},
  {label:"Techlets-Easypaisa", value:"techlets-easypaisa"},
  {label:"Hashir-Easypaisa", value:"hashir-easypaisa"},
  {label:"Elohim-Easypaisa", value:"elohim-easypaisa"},
  {label:"Adonai-Easypaisa", value:"adonai-easypaisa"},
];

const PK_CREDIT_CARD_TYPES = [
  {label:"Stripe", value:"stripe"},
  {label:"Hashir Jazzcash Credit Card", value:"hashir-credit-card"},
];

const VALUE_TYPES = [
  {label:"Fixed", value:"fixed"},
  {label:"Percentage", value:"percent"},
];

const INITIAL_VOUCHER_CONFIG = {
  vendorMargin: {value: 2, type: "percent"},
  withholdingTax: {value: 4, type: "percent"},
  repatriationCost: {value: 5, type: "percent"},
  salesRate: {value: 10, type: "percent"},
  merchantCost: {value: 1.5, type: "percent"},
  marginDistribution: {walee: 80, merchant: 20 },
}

const ServiceFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {
    getService,
    addService,
    editService,
    getServiceData,
    serviceId,
  } = props;
  const [carriers, setCarriers] = useState<SelectFormtted[]>([]);
  const [merchants, setMerchants] = useState<SelectFormtted[]>([]);
  const [operators, setOperators] = useState<OperatorsType[]>([]);
  const [filteredOperators, setFilteredOperators] = useState<SelectFormtted[]>(
    []
  );
  const [selectedOperators, setSelectedOperators] = useState<string[]>([]);

  const [data, setData] = useState({
    _id: "",
    country: "",
    merchant: "",
    packages: [{ d: "", w: "", m: "", y: "", oneTime: "" }],
    serviceName: "",
    jcServiceName:"",
    epServiceName:"",
    ccServiceName:"",
    serviceProvider: "Easypaisa",
    operatorName: "",
    subscriptionFrequency: "",
    currency: "",
    price: "",
    disbursementAmount:"",
    autoDisburse: false,
    perTransactionCharge:"",
    epPerTransactionCharge:"",
    serviceType:"",
    notificationApi: "",
    tokenApi: "",
    portalUrl: "",
    carrierShare: 0,
    merchantShare: "",
    waleeShare: "",
    clickIdApi: "",
    voucherConfig: INITIAL_VOUCHER_CONFIG 
  });

  const validation = Yup.object().shape({
    country: Yup.string().required("Please select a country"),
    merchant: Yup.string().required("Please select merchant"),
    serviceProvider: Yup.string().required("Please select Carrier"),
    serviceName: Yup.string()
      .min(2, "Too short service name.")
      .max(50, "Too long service name")
      .required("Please provide valid service name"),
    currency: Yup.string()
      .min(2, "Provided currency is invalid")
      .max(4, "Provided currency is invalid")
      .required("Please provide currency"),
    serviceType: Yup.string().required("Please select service type"),
    notificationApi: Yup.string().optional(),
    tokenApi: Yup.string().optional(),
    portalUrl: Yup.string().optional(),
    merchantShare: Yup.number().required("Please provide valid merchant share"),
    waleeShare: Yup.number().required("Please provide valid walee share"),
  });

  const fetchService = useCallback(
    async (id) => {
      try {
        const { payload: service } = await getService(id);
        setData({
          _id: service._id,
          country: service.country,
          merchant: service.merchant,
          packages: service.packages,
          voucherConfig: service?.voucherConfig || INITIAL_VOUCHER_CONFIG,
          serviceName: service.serviceName,
          jcServiceName: service.jcServiceName,
          epServiceName: service.epServiceName,
          ccServiceName: service.ccServiceName,
          serviceProvider: service.serviceProvider,
          operatorName: service.operatorName,
          subscriptionFrequency: service.subscriptionFrequency,
          currency: service.currency,
          price: service.price,
          autoDisburse: service.autoDisburse || false,
          perTransactionCharge: service.perTransactionCharge,
          epPerTransactionCharge: service.epPerTransactionCharge,
          disbursementAmount: service.disbursementAmount,
          notificationApi: service.notificationApi || "",
          tokenApi: service.tokenApi,
          clickIdApi: service.clickIdApi || "",
          portalUrl: service.portalUrl,
          carrierShare: service.carrierShare,
          waleeShare: service.waleeShare,
          merchantShare: service.merchantShare,
          serviceType: service.serviceType
        });
        setSelectedOperators(service.paymentMethods);
        const filterOps = operators.filter(
          (op) => op.country === service.country
        );
        const formatted: SelectFormtted[] = [];
        filterOps.forEach((op) => {
          formatted.push({ label: op.name, value: op.name });
        });
        setFilteredOperators(formatted);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getService, operators]
  );

  useEffect(() => {
    const id = serviceId;
    if (id === "new") return;
    fetchService(id);
  }, [serviceId, fetchService]);

  useEffect(() => {
    (async () => {
      const { payload } = await getServiceData();
      const { merchants, carriers, operators } = payload;
      const formattedMerchants: SelectFormtted[] = [];
      const formattedCarriers: SelectFormtted[] = [];
      merchants.forEach((mer) => {
        formattedMerchants.push({ label: mer.name, value: mer._id });
      });
      carriers.forEach((mer) => {
        formattedCarriers.push({ label: mer.name, value: mer.name });
      });
      setMerchants(formattedMerchants);
      setCarriers(formattedCarriers);
      setOperators(operators);
    })();
  }, [getServiceData]);

  const getCountryOperators = async (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("country", val);
    const filterOps = operators.filter((op) => op.country === val);
    const formatted: SelectFormtted[] = [];
    filterOps.forEach((op) => {
      formatted.push({ label: op.name, value: op.name });
    });
    setFilteredOperators(formatted);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clonedState = [...selectedOperators];
    const selectedOp = event.currentTarget.name;
    const index = clonedState.indexOf(selectedOp);
    if (index < 0) {
      setSelectedOperators((prevState) => [...prevState, selectedOp]);
    } else {
      setSelectedOperators(clonedState.filter((op) => op !== selectedOp));
    }
  };

  const handleSubmit = async (vals) => {
    vals["paymentMethods"] = selectedOperators;
    if (data._id) {
      await editService(vals);
      history.push("/services");
    } else {
      await addService(vals);
      history.push("/services");
    }
  };
  const countries = [
    { label: "Pakistan", value: "Pakistan" },
    { label: "China", value: "China" },
    { label: "Egypt", value: "Egypt" },
    { label: "Singapore", value: "Singapore" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "United States", value: "United States" },
  ];

  const serviceTypes = [
    { label: "Loan", value: "Loan" },
    { label: "Voucher", value: "Voucher" },
    { label: "Others", value: "Others" },
  ];

  return (
    <>
      <PageHeader title="Service Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          height: "80vh",
          overflow: "auto",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Box sx={{ width: "65%" }}>
                  <Grid container spacing={4} marginY={2}>
                    <Grid item lg={6} xs={12}>
                      <SelectComponent
                        value={formik.values.merchant}
                        onSelect={formik.handleChange("merchant")}
                        menuItems={merchants}
                        placeHolder="Select Merchant"
                        hasError={!!formik.errors.merchant}
                        errorMessage={formik.errors.merchant as string}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <SelectComponent
                        value={formik.values.country}
                        onSelect={(e) => getCountryOperators(e, formik)}
                        menuItems={countries}
                        placeHolder="Select Country"
                        hasError={!!formik.errors.country}
                        errorMessage={formik.errors.country as string}
                      />
                    </Grid>
                  </Grid>
                  {filteredOperators.length > 0 && (
                    <Box sx={{ border: "1px solid #f1f1f1", padding: "1rem" }}>
                      <Box sx={{ color: "#3C3C3C", fontWeight: "bold" }}>
                        Payment methods
                      </Box>
                      {filteredOperators.map(({ label, value }, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={index}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 3,
                            }}
                          >
                            {label}
                          </Box>
                          <Switch
                            name={value}
                            checked={selectedOperators.includes(value)}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Service Info
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Service Name"}
                          value={formik.values.serviceName}
                          handleTextChange={formik.handleChange("serviceName")}
                          hasError={!!formik.errors.serviceName}
                          errorMessage={formik.errors.serviceName as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.serviceProvider}
                          onSelect={formik.handleChange("serviceProvider")}
                          menuItems={carriers}
                          placeHolder="Select Carrier"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.serviceType}
                          onSelect={formik.handleChange("serviceType")}
                          menuItems={serviceTypes}
                          placeHolder="Select Service Type"
                          hasError={!!formik.errors.serviceType}
                          errorMessage={formik.errors.serviceType as string}
                        />
                      </Grid>
                     {selectedOperators.includes("Jazzcash") && <Grid item lg={6} xs={12}>
                      <SelectComponent
                          value={formik.values.jcServiceName}
                          onSelect={formik.handleChange("jcServiceName")}
                          menuItems={JC_TYPES}
                          placeHolder="Select JazzCash Service"
                          hasError={!!formik.errors.jcServiceName}
                          errorMessage={formik.errors.jcServiceName as string}
                        />
                      </Grid>}
                     {selectedOperators.includes("Easypaisa") && <Grid item lg={6} xs={12}>
                      <SelectComponent
                          value={formik.values.epServiceName}
                          onSelect={formik.handleChange("epServiceName")}
                          menuItems={EP_TYPES}
                          placeHolder="Select Easypaisa Service"
                          hasError={!!formik.errors.epServiceName}
                          errorMessage={formik.errors.epServiceName as string}
                        />
                      </Grid>}
                     {selectedOperators.includes("Credit Card") &&  <Grid item lg={6} xs={12}>
                      <SelectComponent
                          value={formik.values.ccServiceName}
                          onSelect={formik.handleChange("ccServiceName")}
                          menuItems={PK_CREDIT_CARD_TYPES}
                          placeHolder="Select Credit Card Service"
                          hasError={!!formik.errors.ccServiceName}
                          errorMessage={formik.errors.ccServiceName as string}
                        />
                      </Grid>}
                    </Grid>
                  </Box>
                  {/*Billing Info*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Billing Info
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Currency (e.g PKR)"}
                          value={formik.values.currency}
                          handleTextChange={formik.handleChange("currency")}
                          hasError={!!formik.errors.currency}
                          errorMessage={formik.errors.currency as string}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/*Disbursement Info*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                      backgroundColor: "#fafafa"
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Disbursement
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                     <Box sx={{display: "flex", justifyContent:"space-between", alignItems:"center", padding:"8.5px 14px", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "4px"}}>
                        <Box>Auto Disburse</Box>
                        <Switch
                            name={"autoDisburse"}
                            checked={formik.values.autoDisburse}
                            onChange={formik.handleChange("autoDisburse")}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                          />
                     </Box>
                      </Grid>
                      <Grid item lg={6}></Grid>
                     { formik.values.autoDisburse && <>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Easypaisa Per Transaction Charge (%)"}
                          value={formik.values.epPerTransactionCharge}
                          handleTextChange={(e)=>formik.setFieldValue("epPerTransactionCharge", parseFloat(e))}
                          hasError={!!formik.errors.epPerTransactionCharge}
                          errorMessage={formik.errors.epPerTransactionCharge as string}
                          type="number"
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Per Transaction Charge (%)"}
                          value={formik.values.perTransactionCharge}
                          handleTextChange={(e)=>formik.setFieldValue("perTransactionCharge", parseFloat(e))}
                          hasError={!!formik.errors.disbursementAmount}
                          errorMessage={formik.errors.disbursementAmount as string}
                          type= "number"
                        />
                      </Grid>
                     </>}
                    </Grid>
                  </Box>

                  {/*Client's APIs*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Client API(s)
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Notification URI"}
                          value={formik.values.notificationApi}
                          handleTextChange={formik.handleChange(
                            "notificationApi"
                          )}
                          hasError={!!formik.errors.notificationApi}
                          errorMessage={formik.errors.notificationApi as string}
                          required={false}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Token URI"}
                          value={formik.values.tokenApi}
                          handleTextChange={formik.handleChange("tokenApi")}
                          hasError={!!formik.errors.tokenApi}
                          errorMessage={formik.errors.tokenApi as string}
                          required={false}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Click ID URI"}
                          value={formik.values.clickIdApi}
                          handleTextChange={formik.handleChange("clickIdApi")}
                          hasError={!!formik.errors.clickIdApi}
                          errorMessage={formik.errors.clickIdApi as string}
                          required={false}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Portal URL"}
                          value={formik.values.portalUrl}
                          handleTextChange={formik.handleChange("portalUrl")}
                          hasError={!!formik.errors.portalUrl}
                          errorMessage={formik.errors.portalUrl as string}
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/*Revenue Shares*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Revenue Shares
                    </Box>
                    <Grid container spacing={3}>
                      {/*<Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Carrier Share"}
                          value={formik.values.carrierShare}
                          handleTextChange={formik.handleChange("carrierShare")}
                          hasError={!!formik.errors.carrierShare}
                          errorMessage={formik.errors.carrierShare as string}
                        />
                      </Grid>*/}
             
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Merchant Share"}
                          value={formik.values.merchantShare}
                          handleTextChange={formik.handleChange(
                            "merchantShare"
                          )}
                          hasError={!!formik.errors.merchantShare}
                          errorMessage={formik.errors.merchantShare as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Walee Share"}
                          value={formik.values.waleeShare}
                          handleTextChange={formik.handleChange("waleeShare")}
                          hasError={!!formik.errors.waleeShare}
                          errorMessage={formik.errors.waleeShare as string}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/*Voucher Config*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Voucher Configuration
                    </Box>
                    <Grid container spacing={3}>

                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Vendor Margin"}
                          value={formik.values.voucherConfig?.vendorMargin?.value}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.vendorMargin.value", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.vendorMargin.value}
                          errorMessage={(formik.errors.voucherConfig as any)?.vendorMargin.value as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.voucherConfig?.vendorMargin?.type}
                          onSelect={(e) => formik.setFieldValue("voucherConfig.vendorMargin.type", e.target.value)}
                          menuItems={VALUE_TYPES}
                          placeHolder="Select Type"
                          hasError={!!formik.errors.voucherConfig?.vendorMargin?.type}
                          errorMessage={formik.errors.voucherConfig?.vendorMargin?.type as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Withholding Tax"}
                          value={formik.values.voucherConfig?.withholdingTax?.value}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.withholdingTax.value", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.withholdingTax.value}
                          errorMessage={(formik.errors.voucherConfig as any)?.withholdingTax.value as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.voucherConfig?.withholdingTax?.type}
                          onSelect={(e) => formik.setFieldValue("voucherConfig.withholdingTax.type", e.target.value)}
                          menuItems={VALUE_TYPES}
                          placeHolder="Select Type"
                          hasError={!!formik.errors.voucherConfig?.withholdingTax?.type}
                          errorMessage={formik.errors.voucherConfig?.withholdingTax?.type as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Repatriation Cost"}
                          value={formik.values.voucherConfig?.repatriationCost?.value}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.repatriationCost.value", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.repatriationCost.value}
                          errorMessage={(formik.errors.voucherConfig as any)?.repatriationCost.value as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.voucherConfig?.repatriationCost?.type}
                          onSelect={(e) => formik.setFieldValue("voucherConfig.repatriationCost.type", e.target.value)}
                          menuItems={VALUE_TYPES}
                          placeHolder="Select Type"
                          hasError={!!formik.errors.voucherConfig?.repatriationCost?.type}
                          errorMessage={formik.errors.voucherConfig?.repatriationCost?.type as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Sales Rate"}
                          value={formik.values.voucherConfig?.salesRate?.value}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.salesRate.value", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.salesRate.value}
                          errorMessage={(formik.errors.voucherConfig as any)?.salesRate.value as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.voucherConfig?.salesRate?.type}
                          onSelect={(e) => formik.setFieldValue("voucherConfig.salesRate.type", e.target.value)}
                          menuItems={VALUE_TYPES}
                          placeHolder="Select Type"
                          hasError={!!formik.errors.voucherConfig?.salesRate?.type}
                          errorMessage={formik.errors.voucherConfig?.salesRate?.type as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Merchant Cost"}
                          value={formik.values.voucherConfig?.merchantCost?.value}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.merchantCost.value", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.merchantCost.value}
                          errorMessage={(formik.errors.voucherConfig as any)?.merchantCost.value as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.voucherConfig?.merchantCost?.type}
                          onSelect={(e) => formik.setFieldValue("voucherConfig.merchantCost.type", e.target.value)}
                          menuItems={VALUE_TYPES}
                          placeHolder="Select Type"
                          hasError={!!formik.errors.voucherConfig?.merchantCost?.type}
                          errorMessage={formik.errors.voucherConfig?.merchantCost?.type as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Walee Share (%)"}
                          value={formik.values.voucherConfig?.marginDistribution?.walee}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.marginDistribution.walee", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.marginDistribution.walee}
                          errorMessage={(formik.errors.voucherConfig as any)?.marginDistribution.walee as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Merchant Share (%)"}
                          value={formik.values.voucherConfig?.marginDistribution?.merchant}
                          handleTextChange={(e)=> formik.setFieldValue("voucherConfig.marginDistribution.merchant", parseInt(e ?? 0))}
                          type="number"
                          hasError={!!(formik.errors.voucherConfig as any)?.marginDistribution.merchant}
                          errorMessage={(formik.errors.voucherConfig as any)?.marginDistribution.merchant as string}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/*Action Button*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      marginLeft: "1rem",
                      width: "15%",
                    }}
                  >
                    <SubmitButton
                      title="Save"
                      handlePress={() => formik.handleSubmit()}
                    />
                  </Box>
                </Box>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  serviceId: ownProps.match.params.serviceId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getService,
  addService: servicesActions.addService,
  editService: servicesActions.editService,
  getServiceData: servicesActions.getServiceData,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ServiceFormComponentRedux = connector(ServiceFormComponent);

export { ServiceFormComponentRedux as ServiceFormComponent };
