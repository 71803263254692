import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  endOfDay,
  endOfToday,
  endOfYesterday,
  startOfDay,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import { isEmpty } from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { RootState } from "&store/store";
import CalendarIcon from "&assets/icons/calendar";
import TotalRevenueIcon from "&assets/icons/total-revenue";
import AmountPouchIcon from '&assets/icons/amount-pouch'
import DisburseAmountIcon from '&assets/icons/disburse-amount'
import {
  dateByFormat,
  getMonthsBefore,
  getWeeksBefore,
  getYearsBefore,
} from "&utils/dates";
import SelectComponent from "&styled/form/select";
import { getCurrentUser } from "&config/getCurrentUser";
import { SubmitButton } from "&styled/button/button.component";
import { DashboardSummaryCard } from "&styled/dashboardSummaryCard/dashboardSummaryCard.component";
import { LineChartComponent } from "&styled/lineChart/lineChart.component";
import { PieChartComponent } from "&styled/pieChart/pieChart.component";
import { disbursementActions } from "./disbursement.slice";
import CustomDateRangeModal from "&styled/customDateModal";

type SelectFormtted = {
  label: string;
  value: string;
};
type PieChartData = {
  name: string;
  value: number;
}
type ReduxProps = ConnectedProps<typeof connector>;

const DisbursementComponent = (props: ReduxProps) => {
  const { getFilterData, searchDisbursementAnalytics, token } = props;
  const user: any = getCurrentUser(token);
  const [selectedDate, setSelectedDate] = useState("");
  const [summary, setSummary] = useState<any>({}) // top layer cards data
  const [transactionOverTime, setTransactionOverTime] = useState<any[]>([])
  const [transactionSummary, setTransactionSummary] = useState<any>({})
  const [dateRange, setDateRange] = useState<any>({});
  const [analytics, setAnalytics] = useState<any>({});
  const [banks, setBanks] = useState<any>([])
  const [paymentBreakdownSummary, setPaymentBreakdownSummary] = useState<any>({})
  const [paymentBreakdownOverTime, setPaymentBreakdownOverTime] = useState<any>([])
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [openCustomRangeModal, setOpenCustomRangeModal] = useState(false);
  const [merchants, setMerchants] = useState<SelectFormtted[]>([]);
  const { t } = useTranslation(["disbursement"]); 

  useEffect(() => {
    (async () => {
      const { payload } = await getFilterData();
      const { operators, merchants } = payload;

      const formattedOperators: SelectFormtted[] = [
        { label: "All payment methods", value: "all" },
      ];
      const formattedMerchants: SelectFormtted[] = [
        { label: "All vendors", value: "all" },
      ];
      merchants?.forEach((mer) => {
        formattedMerchants.push({ label: mer.name, value: mer.name });
      });
      operators.forEach((operator) => {
        formattedOperators.push({ label: operator.name, value: operator._id });
      });

      setMerchants(formattedMerchants);
     
    })();
    if (user.userTypeId !== "Walee") {
      setSelectedMerchant(user.userTypeId);
    } else {
      setSelectedMerchant("all");
    }
    setSelectedDate("last-week");
    setDateRange({
      dateFrom: getWeeksBefore(1),
      dateTo: endOfToday(),
    });
  }, [
    getFilterData,
    searchDisbursementAnalytics,
    user.userTypeId,
  ]);
  const getComputedValues = (arr) => {
    const vals:PieChartData[] = [];
    arr.forEach((item)=>{
      const existingVals = vals.map((o)=>o.name);
      if(!existingVals.includes(item.bankName)){
          vals.push({name: item.bankName, value: parseInt(item.bankCount)})
      }else{
        const index = vals.findIndex(n => n.name === item.bankName)
        vals[index].value += parseInt(item.bankCount)
      }
    })
return vals;
  }
  useEffect(() => {
    (async () => {
      const searchData = {
        dateFrom: getWeeksBefore(1),
        dateTo: endOfToday(),
      };
      if (user.userTypeId !== "Walee") {
        searchData["merchant"] = user.userTypeId;
      } else {
        searchData["merchant"] = "all";
      }
      const { payload: analytics } = await searchDisbursementAnalytics(
        searchData
      );
     
      const summaryData = {
        totalDepositedAmount: 0,
        totalBilledAmount: 0,
        totalRemainingAmount: 0,
        noOfLoans: 0,
        loanAmount: 0,
      };
      const transactionsOverTime:any = []
      const paymentBreakdownOverTime:any = []
      const transactionObj = {successful:0, pending: 0, rejected: 0}
      let banksData: PieChartData[] = []
      const obj = {"wallet":0, "banks":0};
      analytics.data?.forEach((an)=>{
        an.totalsTransactions
        .forEach((tr)=>{
          summaryData.totalDepositedAmount += tr.totalDepositedAmount;
          summaryData.totalBilledAmount += tr.totalBilledAmount;
          summaryData.totalRemainingAmount += tr.loanAccounts.availableBalance;
          summaryData.noOfLoans += tr.loanAccounts.totalTransactions;
          summaryData.loanAmount += tr.loanAccounts.totalTransactionsAmount
        })
       an.paymentBreakdown.forEach((pd)=>{
        const pdObj = {wallets: 0, banks: 0, date: pd.createdDate};
        if(pd.payoutType === "WALLET") {
          obj.wallet += pd.amount
          pdObj.wallets = pd.amount
        };
        if(pd.payoutType === "BANK") {
          obj.banks += pd.amount
          pdObj.banks += pd.amount
        };
        paymentBreakdownOverTime.push(pdObj)
       })
  
      banksData = getComputedValues(an.banks)
        transactionsOverTime.push({successful: an.successful, pending: an.pending, rejected: an.rejected, date: an.createdDate})
        transactionObj.successful += an?.successful;
        transactionObj.rejected += an?.rejected;
        transactionObj.pending += an?.pending;
      })

      //formatPaymentBreakdownSummary(analytics?.paymentBreakdown)
      setPaymentBreakdownSummary(obj)
      setSummary(summaryData)
      setPaymentBreakdownOverTime(paymentBreakdownOverTime)
      setTransactionOverTime(transactionsOverTime)
      setBanks(banksData)
      setTransactionSummary(transactionObj)
      setAnalytics(analytics?.data);
    })();
  }, [
    searchDisbursementAnalytics,
    user.userTypeId,
  ]);
  const handleDateSelection = (e) => {
    const value = e?.target?.value;
    if (value === "today") {
      setDateRange({
        dateFrom: startOfToday(),
        dateTo: endOfToday(),
      });
    } else if (value === "yesterday") {
      setDateRange({
        dateFrom: startOfYesterday(),
        dateTo: endOfYesterday(),
      });
    } else if (value === "last-week") {
      setDateRange({
        dateFrom: getWeeksBefore(1),
        dateTo: endOfToday(),
      });
    } else if (value === "last-month") {
      setDateRange({
        dateFrom: getMonthsBefore(1),
        dateTo: endOfToday(),
      });
    } else if (value === "last-year") {
      setDateRange({
        dateFrom: getYearsBefore(1),
        dateTo: endOfToday(),
      });
    } else if (value === "custom-dates") {
      setOpenCustomRangeModal(true);
    }
    setSelectedDate(value);
  };

  const handleCloseModal = () => {
    // If user closed modal reset value to none
    setOpenCustomRangeModal(false);
    setSelectedDate("last-week");
  };

  const handleCustomRangeModalConfirmation = (dateFrom: Date, dateTo: Date) => {
    setDateRange({
      dateFrom: startOfDay(dateFrom),
      dateTo: endOfDay(dateTo),
    });
    setOpenCustomRangeModal(false);
  };

  const getCustomDatesLabel = () => {
    return selectedDate === "custom-dates" && !isEmpty(dateRange)
      ? dateByFormat(dateRange?.dateFrom, "DD-MM-YYYY") +
          " - " +
          dateByFormat(dateRange?.dateTo, "DD-MM-YYYY")
      : "Custom Dates";
  };

 
  const handleMerchant = (e) => {
    setSelectedMerchant(e.target.value);
  };

  const handleSearch = async () => {
    const searchData = {
      merchant: selectedMerchant,
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
 
    };
    const { payload: analytics } = await searchDisbursementAnalytics(
      searchData
    );
   
    const summaryData = {
      totalDepositedAmount: 0,
      totalBilledAmount: 0,
      totalRemainingAmount: 0,
      noOfLoans: 0,
      loanAmount: 0,
    };
    const transactionsOverTime:any = []
    const paymentBreakdownOverTime:any = []
    const transactionObj = {successful:0, pending: 0, rejected: 0}
    let banksData: PieChartData[] = []
    const obj = {"wallet":0, "banks":0};
    analytics.data?.forEach((an)=>{
      an.totalsTransactions
      .forEach((tr)=>{
        summaryData.totalDepositedAmount += tr.totalDepositedAmount;
        summaryData.totalBilledAmount += tr.totalBilledAmount;
        summaryData.totalRemainingAmount += tr.loanAccounts.availableBalance;
        summaryData.noOfLoans += tr.loanAccounts.totalTransactions;
        summaryData.loanAmount += tr.loanAccounts.totalTransactionsAmount
      })
     an.paymentBreakdown.forEach((pd)=>{
      const pdObj = {wallets: 0, banks: 0, date: pd.createdDate};
      if(pd.payoutType === "WALLET") {
        obj.wallet += pd.amount
        pdObj.wallets = pd.amount
      };
      if(pd.payoutType === "BANK") {
        obj.banks += pd.amount
        pdObj.banks += pd.amount
      };
      paymentBreakdownOverTime.push(pdObj)
     })

    banksData = getComputedValues(an.banks)
      transactionsOverTime.push({successful: an.successful, pending: an.pending, rejected: an.rejected, date: an.createdDate})
      transactionObj.successful += an?.successful;
      transactionObj.rejected += an?.rejected;
      transactionObj.pending += an?.pending;
    })

    //formatPaymentBreakdownSummary(analytics?.paymentBreakdown)
    setPaymentBreakdownSummary(obj)
    setSummary(summaryData)
    setPaymentBreakdownOverTime(paymentBreakdownOverTime)
    setTransactionOverTime(transactionsOverTime)
    setBanks(banksData)
    setTransactionSummary(transactionObj)
    setAnalytics(analytics?.data);
  };

  const formateSummary = (summary) => {
    if (Array.isArray(summary)) {
      const revSummary = summary.reduce((acc, cur) => {
        acc["waleeShare"] = (acc["waleeShare"] || 0) + cur["waleeShare"];
        acc["totalAmount"] = (acc["totalAmount"] || 0) + cur["totalAmount"];
        acc["totalNumberOfTransactions"] =
          (acc["totalNumberOfTransactions"] || 0) +
          cur["totalNumberOfTransactions"];

        return acc;
      }, {});
      return revSummary;
    }
  };

  const formateTransactionRate = (transactionRates) => {
   
    const formattedTransactionRates: any[] = [];
   
      formattedTransactionRates.push({
        name: "Successful",
        value: transactionRates.successful,
      });
      formattedTransactionRates.push({
        name: "Rejected",
        value: transactionRates.rejected,
      });
      formattedTransactionRates.push({
        name: "Pending",
        value: transactionRates.pending,
      });
  
    return formattedTransactionRates;
  };

  return (
    <Box sx={{ overflow: "auto", height: "95%" }}>
    {/*Heading and Search filters*/}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          color: "#111111",
          font: "normal normal 600 20px/30px Poppins",
        }}
      >
        Payout Overview
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <SelectComponent
          onSelect={(e) => handleDateSelection(e)}
          value={selectedDate}
          size="small"
          Icon={CalendarIcon}
          onClick={(e) => {
            if (e?.target?.dataset?.value !== "custom-dates") return;
            setOpenCustomRangeModal(true);
          }}
          menuItems={[
            { label: "Today", value: "today" },
            { label: "Yesterday", value: "yesterday" },
            { label: "Last 7 days", value: "last-week" },
            { label: "Last 30 days", value: "last-month" },
            { label: getCustomDatesLabel(), value: "custom-dates" },
          ]}
        />
        {user.userTypeId === "Walee" && (
          <SelectComponent
            onSelect={(e) => handleMerchant(e)}
            value={selectedMerchant}
            size="small"
            menuItems={merchants || []}
          />
        )}
        <Box>
          <SubmitButton title={"Search"} handlePress={handleSearch} />
        </Box>
      </Box>
    </Box>
    {/*Dashboard OverView*/}

    <Grid container spacing={1} marginTop={2}>
      <Grid item lg={4} md={1}>
        <DashboardSummaryCard
          title="Total Amount"
          Icon={AmountPouchIcon}
          extraProps={{key1: "Deposited", value1: summary.totalDepositedAmount || "0", key2: "Remaining", value2: summary.totalRemainingAmount, isCurrency: true}}
        />
      </Grid>
      <Grid item lg={4} md={1}>
        <DashboardSummaryCard
          title="Total Payout"
          Icon={DisburseAmountIcon}
          extraProps={{key1: "No. of payouts", value1: summary.noOfLoans, key2: "Payout Amount", value2: summary.loanAmount}}
        />
      </Grid>
      <Grid item lg={4} md={1}>
        <DashboardSummaryCard
          title="Payment Breakdown"
          titleValue={formateSummary(analytics?.summary)?.totalAmount}
          Icon={TotalRevenueIcon}
          extraProps={{key1: "Bank Transfers", value1: paymentBreakdownSummary.banks, key2: "Digital Wallets", value2: paymentBreakdownSummary.wallet, isCurrency: true}}
        />
      </Grid>
      {/*Revenue Over Time*/}
      <Grid item lg={8} md={1}>
        <LineChartComponent
          title="Payout Transaction Overtime"
          subTitleDark="Successful"
          subTitleLight="Rejected"
          subTitleBlack="Pending"
          dataKeyDark="successful"
          dataKeyLight="rejected"
          dataKeyBlack="pending"
          heading={"No of Transactions"}
          isCurrency={true}
          data={transactionOverTime || []}
        />
      </Grid>
      {/*Transaction rate*/}
      <Grid item lg={4} md={1}>
        <PieChartComponent
          data={formateTransactionRate(transactionSummary)}
          blackProp={true}
          title="Transaction Breakdown"
        />
      </Grid>
      {/*Transaction Over Time*/}
      <Grid item lg={6} md={1}>
      <PieChartComponent
          data={banks}
          title="Banks"
          hasLegend={true}
        />
      </Grid>
      {/*Transaction by payment method*/}
      <Grid item lg={6} md={1}>
      <LineChartComponent
          title="Payment Breakdown"
          subTitleDark="Bank Transfers"
          subTitleLight="Digital Wallets"
          dataKeyDark="banks"
          dataKeyLight="wallets"
          heading={"Amount (PKR)"}
          isCurrency={true}
          data={paymentBreakdownOverTime || []}
        />
      </Grid>
    </Grid>
    <CustomDateRangeModal
        open={openCustomRangeModal}
        handleClose={() => handleCloseModal()}
        onConfirm={(dateFrom, dateTo) => handleCustomRangeModalConfirmation(dateFrom, dateTo)}
      />
  </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
      token: state.login.token,
});

const mapDispatchToProps = {
  getFilterData: disbursementActions.getFilterData,
  searchDisbursementAnalytics: disbursementActions.searchDisbursementAnalytics,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const DisbursementComponentRedux = connector(DisbursementComponent);

export { DisbursementComponentRedux as DisbursementComponent };
