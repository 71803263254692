import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Box, Card, CardContent } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

import { RootState } from "&store/store";
import { vouchersActions } from "./vouchers.slice";
import { carriersActions } from "../carriers/carriers.slice";
import {  infoAlert } from "&config/swalGenerator";
import FabButton from "&styled/FabButton";
import PageHeader from "&styled/page-header";
import { Modal } from "&styled/modal/modal.component";
import VoucherDetails from './voucherDetail';
import EyeIcon from "&assets/icons/eye";

type ReduxProps = ConnectedProps<typeof connector>;

const VouchersComponent = (props: ReduxProps) => {

  const {
    state,
    getVouchers,
    getCarriers,
    getVouchersDetails,
    getVouchersBalance
  } = props;
  const { data } = state; // Accessing data from Redux state
  const [pageSize, setPageSize] = useState(25);
  const [vendors, setVendors] = useState([]); // State to hold the list of vendors
  const [balance, setBalance] = useState(0);
  const [selectedVendor, setSelectedVendor] = useState(""); // State to hold the selected vendor
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal
  const [modalContent, setModalContent] = useState<any>(null); // State to hold modal content


  const columns: GridColDef[] = [

    {
      field: "icon_url",
      headerName: "Image",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img src={params.value} alt="icon" style={{ width: '100px', height: '100px' }} />
      ),
    },
    {
      field: "voucher_name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "voucher_code",
      headerName: "Code",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
   (async()=>{
    const {payload} = await getCarriers();
    setVendors(payload)
   })()
  }, [getCarriers]);

  useEffect(() => {
    if (selectedVendor !== "" && selectedVendor !== "Select Vendor") {
      (async () => {
        const [balanceData] = await Promise.all([
          getVouchersBalance(selectedVendor),
          getVouchers(selectedVendor),
        ]);
        
        setBalance(balanceData.payload.data.balance);
      })();
    }
  }, [getVouchers, getVouchersBalance, selectedVendor]);



  const handleVendorChange = (event: any) => {
    if (event.target.value !== "Unipin" ) {
      infoAlert("Only Unipin vouchers are available at the moment", ""); // Fix: Pass two arguments to successAlert
      return;
    }
    setSelectedVendor(event.target.value as string);

  };

  const renderButtons = (row) => {
    return <Box sx={{ display: "flex", gap: 2 }}>{renderBtnShow(row)}</Box>;
  };

  const renderBtnShow = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) => handleViewVoucherDetail(row)}
      >
        <EyeIcon />
      </FabButton>
    );
  };

  const handleViewVoucherDetail = async (row) => {
    try {
      const vendor = selectedVendor; // Assuming you need the selected vendor here
      const voucherId = row.voucher_code; // Assuming _id is the voucher ID
      const response = await getVouchersDetails({ vendor, voucherId });
      setModalContent(response); // Assuming response.data contains the voucher details
      setIsModalOpen(true); // Open the modal
    } catch (error) {
      console.error("Error fetching voucher details:", error);
    }
  };

  return (
    <>
      <PageHeader
        title="Vouchers"
        vendors={vendors}
        selectedVendor={selectedVendor}
        onVendorChange={handleVendorChange}
        balance={balance}
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>

            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data} // Using data from Redux state
                rowHeight={100}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row.voucher_code}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
      <Modal
        title="Voucher Details"
        open={isModalOpen}
        setOpen={setIsModalOpen}
        maxWidth="md"
      >
        {modalContent && (
          <VoucherDetails modalContent={modalContent} />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.vouchers,
});

const mapDispatchToProps = {
  getCarriers: carriersActions.getCarriers,
  getVouchers: vouchersActions.getVouchers,
  getVouchersDetails: vouchersActions.getVouchersDetails,
  getVouchersBalance: vouchersActions.getVouchersBalance,
  // setData: vouchersActions.setData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const VouchersComponentRedux = connector(VouchersComponent);

export { VouchersComponentRedux as VouchersComponent };
